import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Coffee, Clock, ExternalLink } from 'react-feather'
import { Tabs, Tab } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../../components/worldmap"

export default class Estonia extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showEcosystemsLink: false,
            showNextLink: false,
            showPrevLink: false
        }

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){
        // Esc key press
        if(event.keyCode === 27) {
            this.setState({
                showEcosystemsLink: true
            });

            setTimeout(() => {
                let linkRef = document.getElementById("esc-action");
                linkRef.click();
            }, 50)
        }

        // Left Arrow key press
        if(event.keyCode === 37) {
            this.setState({
                showPrevLink: true
            });

            setTimeout(() => {
                let prevLinkRef = document.getElementById("prev-action");
                prevLinkRef.click();
            }, 50)
        }

        // Right Arrow key press
        if(event.keyCode === 39) {
            this.setState({
                showNextLink: true
            });

            setTimeout(() => {
                let nextLinkRef = document.getElementById("next-action");
                nextLinkRef.click();
            }, 50)
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        return (
            <Layout>
                {
                    this.state.showEcosystemsLink &&
                    <AniLink id="esc-action" fade to="/startup-visas/" duration={0.4}></AniLink >
                }
                {
                    this.state.showPrevLink &&
                    <AniLink id="prev-action" fade to="/startup-visas/canada/" duration={0.4}></AniLink >
                }
                {
                    this.state.showNextLink &&
                    <AniLink id="next-action" fade to="/startup-visas/latvia/" duration={0.4}></AniLink >
                }
                <SEO title="Estonia's Startup Visa"  description="With no degree requirement and no minimum investment, it takes 60 days to process the Estonian startup visa, at a cost $88 for the application, and a minimum of $1,994 in personal funds. Apply now."/>
                <div id="top-part">
                <div id="back">
                    <AniLink fade to="/startup-visas" duration={0.4}>
                    <em>{'<-'}</em> All Startup Visas
                    </AniLink>
                </div>
                <div id="contains-map">
                <div id="estonia_map_container">
                <WorldMap />
                </div>
                </div>
                <div id="left-col">
                    <div id="left-col-content">
                    <div className="page-icon-container" style={{fontSize:'48px'}}>
                    <span role="img" aria-label="Estonia">🇪🇪</span>

                    </div>
                    <h1 id="country-header">Estonia's Startup Visa</h1>

                    </div>
                </div>
                <div id="right-col">
                
                    <AniLink fade to="/interviews/estonia" className="colled-many" duration={0.4}>
                    <div className="long-container">
                        
                        <div className="item-header">
                        <div className="long-icon-container">
                            <Coffee className="slack-icon"/> 
                        </div>
                        Interview
                        <div className="item-arrow">
                            →
                        </div>
                        </div>
                        <div className="item-description">
                        </div>

                    </div>
                    </AniLink>
                    <AniLink fade to="/ecosystems/estonia" duration={0.4}>
                    <div className="long-container">
                        
                        <div className="item-header">
                        <div className="long-icon-container">
                            <Globe className="slack-icon"/> 
                        </div>
                        Ecosystem
                        <div className="item-arrow">
                            →
                        </div>
                        </div>
                        <div className="item-description">
                        </div>

                    </div>
                    </AniLink>

                    <OutboundLink href="https://www.startupestonia.ee/visa" target="_blank" rel="noopener noreferrer">
                    <div className="external-container">
                        <div className="item-header">
                        <div className="long-icon-container">
                        <Globe className="slack-icon"/> 
                        </div>

                        Official Website
                        <div className="item-arrow">
                        <ExternalLink className="slack-icon"/> 
                        </div>
                        </div>
                        <div className="item-description">
                        </div>

                    </div>
                    </OutboundLink>

                    <OutboundLink href="https://startupincluder.com/#/company/application/new/22/fbscrckdd1hmjejisjgw3x73wbfbcz58" target="_blank" rel="noopener noreferrer">
                    <div className="external-container">
                        
                        <div className="item-header">
                        <div className="long-icon-container">
                        <Globe className="slack-icon"/> 
                        </div>
                        Apply Now
                        <div className="item-arrow">
                        <ExternalLink className="slack-icon"/> 
                        </div>
                        </div>
                        <div className="item-description">
                        </div>

                    </div>
                    </OutboundLink>
                
                
                </div>
                </div>
                <div className="content-container">

                    <Tabs defaultActiveKey="visa-description" id="country-tabs">
                        <Tab eventKey="visa-description" title="Visa Information" id="visa-information-tab">
                        <p>The startup visa gives foreign entrepreneurs the opportunity to settle in Estonia for up to 18 months to establish their startup. A visa for startup entrepreneurship may be issued for up to 12 months and extended for up to 6 months days. For prospective startup entrepreneurs who have established their startup in Estonia and wish to stay longer, it is possible to apply for a temporary residence permit for entrepreneurship. For startups, the general investment requirement (65 000 EUR) to apply for a residence permit for entrepreneurship does not apply. A temporary residence permit for entrepreneurship may be issued for up to five years.
                        </p>
                        <table className="country-table">
                            <tr>
                            <td className="table-label">Degree Required</td>
                            <td className="table-value">Not Required</td>
                            </tr>
                            <tr>
                            <td className="table-label">Visa Duration</td>
                            <td className="table-value">12 months</td>
                            </tr>
                            <tr>
                            <td className="table-label">Bootstrappers</td>
                            <td className="table-value">Yes</td>
                            </tr>
                            <tr>
                            <td className="table-label">Visa Fees</td>
                            <td className="table-value">$88</td>
                            </tr>
                            <tr>
                            <td className="table-label">Personal Funds</td>
                            <td className="table-value">$1,994</td>
                            </tr>
                            <tr>
                            <td className="table-label">Minimum Investment</td>
                            <td className="table-value">$0</td>
                            </tr>
                            <tr>
                            <td className="table-label">Processing Time</td>
                            <td className="table-value">60 days</td>
                            </tr>
                        </table>
                        </Tab>
                        <Tab eventKey="reviews" title="Founder Reviews" id="visa-information-tab">
                        <div className="jobs-container">
                            <div className="review-container">
                                <div className="job-description">
                                    <p>I’ve managed to successfully immigrate to Estonia, incorporate, and start my business. It was a really smooth process that didn’t take much effort besides getting the paperwork in order. Really recommended, it’s really a great place to be and build the future.</p>
                                </div>
                                <div className="interview-details">
                                    <div className="left-details">
                                    <div className="job-company">
                                    Ziyad Basheer
                                    </div>
                                    <div className="job-tags">
                                    <div className="job-tag">
                                        <div className="job-tag-text">
                                        Founder
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="right-details">
                                    <div className="job-item">
                                        <div>
                                        <Clock className="job-icon"/>
                                        </div>
                                        Processed in 15 days
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Tab>
                    </Tabs>
                </div>
            </Layout>
        )
    }
}